<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        {{ `Cromossomo ${selected.order}` }}
      </v-card-title>
      <v-card-text class="my-5">
        <property-value property="Descrição" :value="selected.description" />
        <v-switch
          v-model="hidePatient"
          :value="hidePatient"
          label="Esconder ao paciente na diagnose"
        />
        <comments v-model="comments" />
      </v-card-text>
      <div class="mt-5 d-flex flex-column">
        <v-btn color="secondary" x-large @click="addToDiagnose">
          Adicionar ao Diagnóstico
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    comments: () =>
      import("@/domains/therapy/shared/presentation/components/Comments"),
  },
  data: () => ({
    comments: {},
    hidePatient: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.chromosomes;
      },
      set(value) {
        this.$store.commit("dialogs/setChromosomes", value);
      },
    },
    selected() {
      return this.$store.state.chromosomes.selected;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    validateIfExistsInDiagnosis() {
      const exists = this.$store.state.diagnosis.diagnose.chromosomes.some(
        (diagnoseChromosomes) =>
          diagnoseChromosomes.chromosome.id === this.selected.id
      );

      if (exists) {
        this.$toasted.global.error({
          message: "Cromossomo já adicionado ao diagnóstico",
        });
        return false;
      }

      return true;
    },
    async addToDiagnose() {
      try {
        if (!this.validateIfExistsInDiagnosis()) return;

        const { therapistComments, patientComments } = this.comments;

        const diagnoseChromosome = {
          therapistComments,
          patientComments,
          chromosome: this.selected,
          hidePatient: this.hidePatient,
        };

        this.$store.commit("diagnosis/addChromosome", diagnoseChromosome);
        this.$toasted.global.success({
          message: "Cromossomo adicionado ao diagóstico",
        });
        this.dialog = false;
      } catch (error) {
        this.$toasted.global.error({
          message: "Erro interno ao adicionar ao diagnóstico",
        });
      }
    },
  },
};
</script>

<style scoped></style>
